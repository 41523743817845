import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Storyblok } from '@imagination/sc-storyblok-lib-js';

// Styling
import './Hyperlink.scss';

// Components
import AnimateElementIn from '../../AnimateIn/AnimateElementIn/AnimateElementIn';
import Text from '../../Modules/Text/Text';
import Session from '../../../classes/session';

class Hyperlink extends Component {
    state = {
        clubs: null,
        masterclasses: null
    }

    openGallery(id) {
        let event = null;

        if (Session.isIE) {
            event = document.createEvent('CustomEvent' );
            event.initCustomEvent( 'showcase', false, false, { id } );
        } else {
            event =  new CustomEvent('showcase', { detail: id });
        }
        window.dispatchEvent(event);
    }

    getNextClub() {
            
        if (this.state.clubs === null) {
            return null;
        }

        const hash = window.location.hash ? window.location.hash.substr(1) : null; 
        const path = hash || window.location.pathname;
        const currentClub = path.match( /\/club\/(?<slug>[\w-]+)/ );

        let index = 0;
        if (currentClub && currentClub.groups && currentClub.groups.slug) {
            const currentSlug = currentClub.groups.slug;
            let ix = this.state.clubs.findIndex( (club) => club.slug === currentSlug );
            if (ix>=0 && ix<this.state.clubs.length) {
                index = ++ix;
                if (index>=this.state.clubs.length) {
                    index = 0;
                }
            } 
        }
        return this.state.clubs[index]
    }

    componentDidMount() {
        Storyblok.fetchShowClubs()
            .then( clubs => this.setState( { clubs } ) )
            .then( () => Storyblok.fetchShowMasterclasses() )
            .then( masterclasses => this.setState( { masterclasses } ) );
    }

    render() {

        const showName = Storyblok.site.active_show.name;

        if (!this.state.clubs && !this.state.masterclasses) {
            return null;
        }

        const getContent = () => {
            return this.props.children || this.props.data.text;
        }

        const getClubSlug = (id) => {
            const club = this.state.clubs.find( c => c.uuid === id );
            return club ? club.slug : '';
        }

        const getMasterclassSlug = (id) => {
            const masterclass = this.state.masterclasses.find( m => m.uuid === id );
            return masterclass ? masterclass.slug : '';
        }

        const getLink = (type) => {
            switch (type) {
                case 'hyperlink-type-club':
                    return ( <Link to={`/${showName}/club/${ getClubSlug(this.props.data.type[0].club) }`} className="hyperlink club-hyperlink">{ getContent() }</Link> );

                case 'hyperlink-type-masterclass':
                    return ( <Link to={`/${showName}/masterclass/${ getMasterclassSlug( this.props.data.type[0].masterclass )}`} className="hyperlink">{ getContent() }</Link> );

                case 'hyperlink-type-page':
                    return ( <Link to={this.props.data.type[0].page === 'home' ? ('/' + showName) : ( '/' + showName + '/' + this.props.data.type[0].page)} className="hyperlink">{ getContent() }</Link> );

                case 'hyperlink-type-external':
                    return ( <a href={this.props.data.type[0].url} target={ this.props.data.type[0].new_window ? '_blank' : '' } className="hyperlink">{ getContent() }</a> );

                case 'hyperlink-type-next-club': {

                    const club = this.getNextClub();
                    if (!club) {
                        return null;
                    }

                    const fmt =  this.props.data.type[0].format || null;
                    if (!fmt && !fmt[0]) {
                        return null;
                    }

                    fmt[0].copy.markup = fmt[0].copy.markup || '{NAME}';
                    fmt[0].copy.markup = fmt[0].copy.markup.replace( /\{name\}/gi, club.shortName )
                    fmt[0].copy.markup = fmt[0].copy.markup.replace( /\{category\}/gi, club.category.name )

                    return (
                        <Link to={`/${showName}/club/${club.slug}`} className="hyperlink next-club-hyperlink">
                            <Text data={fmt[0]}></Text>
                        </Link>
                    )
    
                }

                case 'hyperlink-type-gallery':
                    return ( <span key="link" className="link" onClick={() => this.openGallery(this.props.data.type[0].gallery)}>{ getContent() }</span> )

                default:
                    return '';
            }
        }

        const type = this.props.data.type && this.props.data.type.length > 0 ? this.props.data.type[0].component : null;

        return ( <AnimateElementIn>{getLink(type)}</AnimateElementIn> );

    }
}

export default Hyperlink;